import refsCommon from "./refsCommon";
const { domains } = refsCommon;

const changeTextDomain = () => {
	if (!domains) return;
	try {
		domains.forEach((el) => (el.textContent = window.location.host));
	} catch (error) {
		throw new Error(error);
	}
};

changeTextDomain();
