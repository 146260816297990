const refsCommon = {
	// //modals
	acceptCookie: document.getElementById("acceptCookie"),

	// //sections

	// forms
	form: document.getElementById("form"),

	// tags
	domains: document.querySelectorAll("#domain"),
};

export default refsCommon;
